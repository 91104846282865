"use client";
import { useEffect, useState, useCallback } from "react";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import useQueryParams from "@/hooks/use-params";
import { Loader } from "@googlemaps/js-api-loader";
import { useLocationStore } from "@/store/location";
import { toast } from "sonner";
import { useClusterContext } from "@/components/providers/clusters-provider";
import { useRouter } from "next/navigation";

const loader = new Loader({
  apiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
  libraries: ["places"],
});

type SearchAddressType = {
  [key: string]: {
    count: number;
    lat: number;
    lng: number;
  };
};

export const locationSearchModel = () => {
  const { query, params } = useQueryParams();
  const { setLocation, address } = useLocationStore();
  const [predictions, setPredictions] = useState<any[]>([]);
  const [searchsAddress, setSearchAddress] = useState<SearchAddressType>({});
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState(address ?? query.address);
  const [showSuggestions, setShowSuggestions] = useState(false);
 
  const searchPlaces = useCallback((input: string) => {
    if (!autocompleteService || !input ) return;
    autocompleteService.getPlacePredictions(
      { input, componentRestrictions: { country: "br" }, types: ["geocode"] },
      (results) => setPredictions(results as any)
    );
  }, [autocompleteService]);
  const router = useRouter()
  const handleSelect = async (address: string) => {
    setInput(address);
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const _params = new URLSearchParams(params);
      _params.set("address", address);
      _params.set("latitude", lat.toString());
      _params.set("longitude", lng.toString());
      setLocation({ lat, lng });
      updateSearchHistory(address, lat, lng);
      updateUrl(_params);
      console.log("Coordenadas selecionadas:", { lat, lng });
    } catch (error) {
      console.error("Erro ao selecionar local:", error);
    }
  };

  // Update the localStorage with search history and re-render state
  const updateSearchHistory = (address: string, lat: number, lng: number) => {
    const updatedAddress = searchsAddress[address]
      ? { ...searchsAddress[address], count: searchsAddress[address].count + 1, lat, lng }
      : { address, count: 1, lat, lng };
    const updatedSearchs = { ...searchsAddress, [address]: updatedAddress };
    setSearchAddress(updatedSearchs);
    localStorage.setItem("searchsAddress", JSON.stringify(updatedSearchs));
  };

  const updateUrl = (params: URLSearchParams) => {
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const handleIncrement = useCallback((address: string) => {
    setSearchAddress((prev) => {
      const updated = { ...prev, [address]: { ...prev[address], count: prev[address].count + 1 } };
      localStorage.setItem("searchsAddress", JSON.stringify(updated));
      return updated;
    });
  }, []);

  const handleClearHistory = () => {
    setSearchAddress({});
    localStorage.removeItem("searchsAddress");
  };

  const getLocation = useCallback(() => {
    if (!navigator.geolocation) {
      console.log("Geolocalização não é suportada neste navegador.");
      return;
    }
    const requestLocation = () => navigator.geolocation.getCurrentPosition(handleSuccess, handleError);

    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          requestLocation();
        } else {
          toast.warning("Você negou o acesso à localização.");
        }
      }).catch(requestLocation); // Fallback
    } else {
      requestLocation();
    }
  }, []);

  const handleSuccess = (position: any) => {
    const { latitude, longitude } = position.coords;
    const params = new URLSearchParams();
    params.set("latitude", latitude.toString());
    params.set("longitude", longitude.toString());
    setLocation({
     lat: latitude,
     lng: longitude
    })
    updateUrl(params);
  };

  const handleError = (error: any) => {
    console.error("Erro ao obter localização:", error.message);
  };

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    searchPlaces(event.target.value);
    if (!event.target.value) {
      setPredictions([]);
      const urlParams = new URLSearchParams(params);
      updateUrl(urlParams);
    }
  };

  useEffect(() => {
    loader.load().then(() => setAutocompleteService(new google.maps.places.AutocompleteService()));
  }, []);

  useEffect(()=>{
    if(!input && query.address){
      setInput(query.address)
    }
  },[query.address])
  useEffect(() => {
    const storedSearches = localStorage.getItem("searchsAddress");
    if (storedSearches) {
      try {
        const parsedSearches = JSON.parse(storedSearches);
        setSearchAddress(parsedSearches);
      } catch (error) {
        console.error("Erro ao carregar histórico de buscas", error);
        setSearchAddress({});
        localStorage.removeItem("searchsAddress");
      }
    }
  }, []);
  return {
    showSuggestions,
    setShowSuggestions,
    searchsAddress,
    handleIncrement,
    getLocation,
    handleClearHistory,
    predictions,
    searchPlaces,
    input,
    setInput,
    handleSelect,
    isOpen,
    setIsOpen,
    handleOnChangeInput
  };
};
