"use client";
import { Clock, Crosshair, Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { NavLink } from "../../../app/(public)/(main)/_components/header";
import { locationSearchModel } from "./location-search.model";

type Props = {
  isLocation?: boolean;
};

export default function LocationSearch({ isLocation = true }: Props) {
  const {
    getLocation,
    isOpen,
    setIsOpen,
    handleOnChangeInput,
    setShowSuggestions,
    handleSelect,
    input,
    predictions,
    showSuggestions,
    searchsAddress,
    handleIncrement,
  } = locationSearchModel();

  return (
    <div className="relative w-full">
      <div className="flex bg-white flex-1 w-full items-center rounded-xl border border-input px-4 py-1">
        <Search />
        <Input
          value={input}
          onChange={handleOnChangeInput}
          onFocus={() => {
            setShowSuggestions(true);
            setIsOpen(true);
          }}
          onBlur={() => setTimeout(() => {
            setShowSuggestions(false);
            setIsOpen(false);
          }, 200)}
          placeholder="Digite um endereço"
          className="w-full p-2 border-none rounded"
        />
      </div>
      {isOpen && (
        <ul className="absolute z-50 flex flex-col w-full bg-white rounded mt-1">
          {isLocation && showSuggestions && input?.length === 0 && (
            <>
              <button
                className="flex items-center justify-center gap-2 p-2 font-bold text-blue-700 hover:text-blue-600 hover:bg-gray-100"
                onClick={getLocation}
              >
                <Crosshair size={16} />
                <p>Buscar pela minha localização atual</p>
              </button>
              <div className="p-2">
                <h1 className="text-base font-bold text-gray-600">Buscas Recentes</h1>
                {Object.keys(searchsAddress).length === 0 && (
                  <p className="py-4 w-full text-center">Nenhuma busca encontrada</p>
                )}
                <ul className="flex flex-col gap-2 p-1">
                  {Object.keys(searchsAddress).map((key, index) => {
                    const search = searchsAddress[key];
                    return (
                      <li
                        key={search.lat + index}
                        className="p-1 py-2 bg-gray-200/40 rounded-md flex justify-between items-center"
                        onClick={() => handleIncrement(key)}
                      >
                        <NavLink
                          pathname="/search"
                          query={{
                            transaction: "Aluguel",
                            address: key,
                            latitude: search.lat.toString(),
                            longitude: search.lng.toString(),
                          }}
                          onClick={()=> handleSelect(key)}
                          className="flex items-center gap-2 p-2 font-bold text-blue-700 hover:text-blue-600 hover:bg-gray-100 underline"
                        >
                          <Clock size={16} />
                          {key}
                        </NavLink>
                        <p className="font-bold bg-blue-600 p-1 text-sm text-white rounded-lg w-7 h-7 text-center flex justify-center items-center">
                          {search.count}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
          <div className={input?.length > 0 ? `flex flex-col gap-2 p-2` : "hidden"}>
            {predictions &&
              predictions.map(({ place_id, description }) => {
                return (
                  <li
                    key={place_id}
                    onClick={() => handleSelect(description)}
                    className="py-2 bg-gray-200/40 rounded-md flex items-center gap-2 p-2 font-bold hover:text-blue-600 hover:bg-gray-100 cursor-pointer"
                  >
                    {description}
                  </li>
                );
              })}
          </div>
        </ul>
      )}
    </div>
  );
}
