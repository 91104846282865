import Image from "next/image";
import { NavLink } from "./header";
import useQueryParams from "@/hooks/use-params";
export default function Logo() {
	const { query } = useQueryParams()
	return (
		<NavLink pathname={"/"}  query={query} className="w-[200px] h-[60px]">
			<Image width={200} height={150} src={"/minb.png"} alt={"Logo"} />
		</NavLink>
	);
}
