import { Routes } from "@/@types/api/routes.enum";
import { Session } from "@/config/auth";
import { fetcher } from "@/lib/fetcher";
import { useMutation } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { toast } from "sonner";
import { FilterOptionsInfoState } from "@/components/globals/filter/options/options.types";
import { useModal } from "@/contexts/modal-provider";

export const useOrderModal = () => {
  const {data: session, status } = useSession() as {data: Session | null, status: string}
  const { setClose } = useModal()
  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return fetcher(Routes.Order, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session?.token}`,
        },
        body: JSON.stringify(data),
      });
    },
    onSuccess() {
      toast.success(`Encomendado com sucesso`)
      setClose()
    },
    onError(e) {
      toast.error(e.message)
    }
  });

  const handleSubmit = async (data: FilterOptionsInfoState) => {
    if(status !== "authenticated"){
      toast.warning("Precisa está autenticado para solicitar")
    }
      //@ts-ignore
      mutation.mutate({ prices: [0, 1000000000], ...data})
      return;
  }
  return {
    handleSubmit,
    isLoading: mutation.isPending
  }
}