'use client'
import CustomModal from '@/components/globals/custom-model'
import { useModal } from '@/contexts/modal-provider'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { signOut, useSession } from "next-auth/react"
import { ChevronDown, LineChart, LogOut, Menu as MenuIcon } from 'lucide-react'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import Logo from './logo'
import { OrderView } from './order/order.view'
import { Session } from '@/config/auth'
import { useLocationStore } from '@/store/location'
import { cn } from '@/lib/utils'
import useQueryParams from '@/hooks/use-params'

interface NavLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  pathname: string;
  children: React.ReactNode;
  query?: {[key: string]: string | string[] | undefined} | any;
}

export const NavLink = ({ pathname, children, query ,  className, ...rest }: NavLinkProps) => {
  const searchParams = useSearchParams();
  const queryParams = Object.fromEntries(searchParams);
  const { location } = useLocationStore()
  return (
      <Link
        href={{
          pathname: pathname,
          query: {
            latitude: location?.lat,
            longitude: location?.lng,
            ...queryParams,
            ...query,
            size: 12,
          },
        }}
        className={cn("flex font-bold items-center text-sm gap-1 transition-colors hover:text-blue-600", className)}
        {...rest}
        
      >
        {children}
      </Link>
  );
};

const Header = () => {
  const { setOpen } = useModal()
  const openModal = () => {
    setOpen(
      <CustomModal type="modal" subheading="Encomendar">
        <OrderView />
      </CustomModal>,
    )
  }
  return (
    <header className="w-full top-0 shadow-sm backdrop-blur-md z-[60] m-auto">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex lg:items-center">
          <Logo />
        </div>
        <nav className="max-lg:hidden flex lg:block  items-center gap-[80px]">
          <ul className="flex space-x-4 gap-3 flex-wrap justify-between">
            {/* <NavLink pathname="/search" query={{ transaction: 'Investimento' }}>
              Investimento
            </NavLink> */}
            <NavLink pathname="/search" query={{ transaction: 'Aluguel' }}>Aluguel</NavLink>
            <NavLink pathname="/search" query={{ transaction: 'Venda' }}>Venda</NavLink>
            <NavLink pathname="/search" query={{ transaction: 'Temporada' }}>Temporada</NavLink>
            <div
              onClick={openModal}
              className="flex cursor-pointer items-center text-sm gap-1 transition-colors hover:text-blue-600"
            >
              <p className="font-bold" translate="no">Encomendar</p>
            </div>
          </ul>
        </nav>
        <div className="flex justify-end  items-center gap-2">
          <div className="flex text-center items-center justify-center space-x-2 max-md:hidden">
            <Link
              href={'/formulary'}
              className="rounded-lg text-center p-3 text-base font-[600] hover:bg-[#063B89]/10"
            >
              Anuncie
            </Link>
            <UserMenu />
          </div>
          <Menu />
        </div>
      </div>
    </header>
  )
}
export default Header

const Menu = () => {
  const { setOpen, setClose } = useModal()
  const openModal = () => {
    setOpen(
      <CustomModal type="modal" subheading="Encomendar">
        <OrderView />
      </CustomModal>,
    )
  }
  const handleOpen = () => {
    setOpen(
      <CustomModal subheading="Menu" type="sheet">
        <div className="flex h-full flex-col justify-between p-4">
          <nav>
            <ul className="space-y-8">
              {/* <NavLink pathname="/search" query={{ transaction: 'Investimento' }} onClick={setClose}>Investimento</NavLink> */}
              <NavLink pathname="/search" query={{ transaction: 'Aluguel' }} onClick={setClose}>Aluguel</NavLink>
              <NavLink pathname="/search" query={{ transaction: 'Venda' }} onClick={setClose}>Venda</NavLink>
              <NavLink pathname="/search" query={{ transaction: 'Temporada' }} onClick={setClose}>Temporada</NavLink>
              <NavLink
                pathname={'/formulary'}
                onClick={setClose}
              >
                Anuncie
              </NavLink>
              <Link href="#" className="flex font-bold items-center text-sm gap-1 transition-colors hover:text-blue-600" onClick={openModal}>Encomendar</Link>
            </ul>
          </nav>
          <UserMenu />
        </div>
      </CustomModal>,
    )
  }

  return (
    <MenuIcon
      width={35}
      height={35}
      className="rounded-sm border border-[#cfd3da] border-border p-1 lg:hidden"
      onClick={handleOpen}
    />
  )
}

const UserMenu = () => {
  const { setClose } = useModal()
  const { data } = useSession() as { data: Session | null }
  const route = useRouter()
  if (data?.customer)
    return (
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center justify-between gap-2 rounded-xl border p-2">
          <div className="flex gap-2 items-center">
            <Avatar className="h-[30px] w-[30px]">
              <AvatarImage
                className={'border-none outline-none'}
                src={data.customer?.avatar}
                alt="@shadcn"
              />
              <AvatarFallback>
                {data?.customer.name[0].toString().toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <p className="w-[75px] truncate">{data?.customer.name}</p>
          </div>
          <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[15rem]">
          <DropdownMenuLabel>Minha conta</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="flex w-full items-center justify-between hover:bg-foreground/10"
            onClick={() => {
              setClose()
              route.push('/dashboard')
            }}
          >
            Minha conta
            <LineChart size={16} />
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex w-full items-center justify-between hover:bg-foreground/10"
            onClick={()=> signOut()}
          >
            Sair
            <LogOut size={16} color="red" />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  return (
    <div className="max-[420px]:flex-col flex w-full gap-2 items-center ">
      <Link
        href="/sign-in"
        onClick={setClose}
        translate="no"
        className="flex w-full items-center justify-center whitespace-nowrap rounded-md bg-[#4279ca] px-5 py-3 text-base font-medium text-white outline-none ring-offset-background transition-colors hover:bg-[#4279ca]/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
      >
        Login
      </Link>
      <Link
        href={'/sign-up'}
        onClick={setClose}
        className="flex lg:hidden items-center w-full justify-center whitespace-nowrap rounded-md bg-[#4279ca] px-5 py-3 text-base font-medium text-white outline-none ring-offset-background transition-colors hover:bg-[#4279ca]/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
      >
        Crie sua conta
      </Link>
    </div>
  )
}
