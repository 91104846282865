import { useOrderModal } from "./order.modal";
import { OptionsFilterView } from "@/components/globals/filter/options/options.view";
import LocationSearch from "@/components/globals/location-search/location-search.view";
export const OrderView = () => {
  const { handleSubmit, isLoading } = useOrderModal()
  return (
    <aside className="flex flex-col gap-2 z-[100] ">
      <LocationSearch isLocation={false} />
      <OptionsFilterView onSubmit={handleSubmit} isLoading={isLoading} isOrder />
    </aside>)
}