"use client";
import { useSearchParams } from "next/navigation";

type FilterProperties = {
  size?: string;
  pag?: string;
  transaction?: string
	sortby?: "asc" | "desc"
	sortdir?: string
  sortBy?: string;
  sortDir?: string;
  bathrooms?: number
  suites?: number
  bedrooms?: number
	address?: string
  rooms?: number
  garages?: number
  areaTotal?: number
  city?: number
  latitude?: number
  longitude?: number
  radius?: number
  areaUtil?: number
  types?: string[]
  characteristics?: string[]
  prices?: string[]
  ids?: string[]
	token?: string
	zoom?: number
	redirect?: string
}

export default function useQueryParams() {
	const searchParams = useSearchParams();

	const setParams = <K extends keyof FilterProperties>(key: K, value: FilterProperties[K]): void => {
		if (typeof window === "undefined") return;
		const params = new URLSearchParams(window.location.search);
	
		if (Array.isArray(value)) {
			params.delete(key);
			value.forEach(val => {
				if (val != null && val !== "") { 
					params.append(key, val.toString());
				}
			});
		} else if (value == null || value === "") {
			params.delete(key); 
		} else {
			params.set(key, value.toString());
		}
	
		const newUrl = `${window.location.pathname}?${params.toString()}`;
		console.log("Redirecting to:", newUrl);
		window.history.pushState({}, "", newUrl);
	};

	const getParam = <K extends keyof FilterProperties>(name: K): FilterProperties[K] | null => {
    const values = searchParams?.getAll(name);
    return values?.length ? (values[0] as FilterProperties[K]) : null;
	};

	const objToParams = (obj: Record<string, string | number | boolean | (string | number | boolean)[]>) => {
    const params = new URLSearchParams();

		Object.entries(obj).forEach(([key, value]) => {
				if (Array.isArray(value)) {
						value.forEach(val => params.append(key, String(val)));
				} else {
						params.append(key, String(value));
				}
		});

		return params.toString(); // Retorna no formato "key=value&key2=value2"
	};
	const getAllParamsAsObject = (): Partial<FilterProperties> => {
		const paramsObj: Partial<FilterProperties> = {};
		const arrayKeys: (keyof FilterProperties)[] = ["prices", "types", "characteristics", "ids"];
	
		searchParams.forEach((value, key) => {
			if (arrayKeys.includes(key as keyof FilterProperties)) {
				// Se já existir um array, adiciona ao existente
				if (paramsObj[key as keyof FilterProperties]) {
					(paramsObj[key as keyof FilterProperties] as string[]).push(value);
				} else {
					paramsObj[key as keyof FilterProperties] = [value] as any;
				}
			} else {
				paramsObj[key as keyof FilterProperties] = value as any;
			}
		});
	
		return paramsObj;
	};


	return {
		setParams,
		getParam,
		params: searchParams.toString(),
		objToParams,
		query:  getAllParamsAsObject(),
	};
}
