import useQueryParams from "@/hooks/use-params"
import { useRouter } from "next/navigation"
import { useEffect, useState } from "react"
import { toast } from "sonner"
import { FilterOptionsInfoState } from "./options.types"
import { useLocationStore } from "@/store/location"
import useLocalStorage from "@/hooks/use-local-storage"

export const useOptionFilter = () => {
  const router = useRouter()
  const { query, objToParams } = useQueryParams()
  const [info, setInfo] = useState<FilterOptionsInfoState>({
    prices: [0,5000000000],
    types: [],
    transaction: "",
    feature: [],
    latitude: query?.latitude,
    longitude: query?.longitude,
    address: query.address,
    ...query,
  })
  const handleSalveFilter = () => {
    toast.success("Salvo com sucesso!")
  }
  const handleResetFilter = () => {
    setInfo({...{prices: [0,5000000000], types: [], transaction: "", feature: [], latitude: query?.latitude, longitude: query?.longitude, address: query.address }})
    const paramns = objToParams(info)
    router.push(`?${paramns}`)
    localStorage.removeItem('filter')
  }

  const handleOnChange = (key: keyof FilterOptionsInfoState, value: any) => {
    setInfo((prev) => {
      const newInfo = { ...prev };
  
      if (value === null) {
        delete newInfo[key];
      } else {
      //@ts-ignore
        newInfo[key] = value; 
      }
      return newInfo;
    });
  };

  useEffect(() => {
    const filter = localStorage.getItem('filter')
    if (filter) {
      setInfo(JSON.parse(filter))
    }
  }, [])
  useEffect(() => {
    localStorage.setItem('filter', JSON.stringify(info))
  }, [info])
  return {
    info,
    setInfo,
    handleSalveFilter,
    handleResetFilter,
    handleOnChange
  }
}